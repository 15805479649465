import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const slider = document.querySelector('.header-slider');
  const slides = document.querySelectorAll('.header-slider__slide');

  if (slider) {
    const args = {
      slidesPerView: 1,
      watchOverflow: true,
      navigation: {
        prevEl: slider.querySelector('.swiper-button-prev'),
        nextEl: slider.querySelector('.swiper-button-next'),
      },
      autoplay: {
        delay: 6000,
      },
    };

    if (1 < slides.length) {
      args.loop = true;
      args.watchOverflow = false;
    }

    new Swiper(slider, args);
  }
});
